import { addApolloState, initializeApollo } from '@/utils/apolloClient'
import { getHomePageData } from '@/api/strapi/homePage'
import type { NextPage, GetServerSidePropsContext } from 'next'
import React from 'react'
import { useQuery } from '@apollo/client'
import {
  Box,
  Card,
  Center,
  Link,
  Text,
  Stack,
  Container,
  Flex,
  Image,
  VStack,
  HStack,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useTranslations } from 'next-intl'

import { SUPPORT_URL } from '@/constants'
import { customerReviews } from 'data/customerReviews'
import { IMiner } from '@/types/miner.interface'
import { IHomePage, IPress } from '@/types/strapi/homePage.interface'

import { getFeaturedMiners } from '@/api/minersListing'

import { ContainerBase } from '@/components/containers/ContainerBase'
import { Hero } from '@/components/Hero'
import { HomepageCard } from '@/components/HomepageCard'
import Carousel from '@/components/carousel/Carousel'
import { SubscribeCard } from '@/components/SubscribeCard'
import { HardwareRackIcon } from '@/components/icons/HardwareRackIcon'
import { BadgeIcon } from '@/components/icons/BadgeIcon'
import { RightArrowIcon } from '@/components/icons/RightArrowIcon'
import { BsStarFill, BsStar } from 'react-icons/bs'
import { HiOutlineMail } from 'react-icons/hi'
import { Loading } from '@/components/Loading'
import { ProductCard } from '@/features/home/ProductCard'
import { GuaranteeIcon } from '@/components/icons/GuaranteeIcon'
import { MostRecentCompassContent } from '@/features/home/MostRecentCompassContent'
import { TalktoSales } from '@/features/home/TalktoSales'
import { getStrapiMedia } from '@/utils/getStrapiMedia'

type Props = {
  homePageData: IHomePage
}

const HomePage: NextPage<Props> = ({ homePageData }) => {
  const t = useTranslations()
  const textGray850 = useColorModeValue('gray.850', 'gray.220')

  const whyCompassText = [
    {
      id: 1,
      title: t('home.why-choose-compass.block1.title'),
      description: t('home.why-choose-compass.block1.description'),
      icon: (
        <HardwareRackIcon
          boxSize={{ base: '50px', md: '50px', lg: '60px' }}
          color={'brand.600'}
        />
      ),
    },
    {
      id: 2,
      title: t('home.why-choose-compass.block2.title'),
      description: t('home.why-choose-compass.block2.description'),
      icon: <BadgeIcon boxSize={{ base: '50px', md: '50px', lg: '60px' }} />,
    },
    {
      id: 3,
      title: t('home.why-choose-compass.block3.title'),
      description: t('home.why-choose-compass.block3.description'),
      icon: (
        <GuaranteeIcon
          boxSize={{ base: '50px', md: '50px', lg: '60px' }}
          color={'brand.600'}
        />
      ),
    },
  ]

  const { data, loading } = useQuery(getFeaturedMiners, {
    fetchPolicy: 'cache-first',
  })
  const textColor = useColorModeValue('black', 'white')
  const bgColorGray220 = useColorModeValue('gray.220', 'gray.800')
  const bgColorGray50 = useColorModeValue('gray.50', 'gray.800')
  const reviewItemWidth = useBreakpointValue({
    base: 1,
    md: 2,
    lg: 3,
    '2xl': 4,
  })

  if (loading) {
    return <Loading text={t('hardware-list.loading')} />
  }

  return (
    <>
      <Hero />
      {homePageData?.advertisementTop?.published && (
        <ContainerBase py={8} id="advertisement-top" maxWidth={'xl'}>
          <Card
            direction={{ base: 'column-reverse', md: 'row' }}
            alignItems="center"
            overflow="hidden"
            bg="black.300"
            color="white"
          >
            {homePageData.advertisementTop.image.data && (
              <Box
                width={{ base: '100%', md: '40%' }}
                flexGrow={1}
                flexShrink={1}
                flexBasis="100%"
                alignSelf="stretch"
              >
                <Image
                  objectFit="cover"
                  height="100%"
                  src={getStrapiMedia(
                    homePageData.advertisementTop.image.data.attributes.url
                  )}
                  alt={
                    homePageData.advertisementTop.image.data.attributes
                      .alternativeText || homePageData.advertisementTop.title
                  }
                />
              </Box>
            )}
            <Box
              width={{ base: '100%', md: '60%' }}
              px={{ base: '16px', lg: '48px' }}
              py={{ base: '16px', lg: '48px' }}
              flexGrow={1}
              flexShrink={1}
              flexBasis="100%"
            >
              {homePageData.advertisementTop.subtitle && (
                <Flex direction={'row'} alignItems="center" mb={2}>
                  {homePageData.advertisementTop.subtitleIcon?.data && (
                    <Image
                      mr={2}
                      maxWidth="32px"
                      src={getStrapiMedia(
                        homePageData.advertisementTop.subtitleIcon.data
                          .attributes.url
                      )}
                      alt={
                        homePageData.advertisementTop.subtitleIcon.data
                          .attributes.alternativeText || ''
                      }
                    />
                  )}
                  <Text
                    fontSize={{ base: '20px', md: '24px' }}
                    letterSpacing="-0.8px"
                    fontWeight={500}
                  >
                    {homePageData.advertisementTop.subtitle}
                  </Text>
                </Flex>
              )}
              <Text
                mb={2}
                fontSize={{ base: '28px', md: '32px' }}
                letterSpacing="-0.8px"
                lineHeight="106.25%"
                fontWeight={700}
                variant="orangeGradient"
              >
                {homePageData.advertisementTop.title}
              </Text>
              {homePageData.advertisementTop.text && (
                <Text mb={{ base: 4, md: 8 }} lineHeight="1.25">
                  {homePageData.advertisementTop.text}
                </Text>
              )}
              {homePageData.advertisementTop.link && (
                <Link
                  href={homePageData.advertisementTop.link.url}
                  isExternal={homePageData.advertisementTop.link.newTab}
                >
                  {homePageData.advertisementTop.link.text}
                  <RightArrowIcon pl={1} width={{ base: '12px' }} />
                </Link>
              )}
            </Box>
          </Card>
        </ContainerBase>
      )}
      <ContainerBase
        pt={{ base: '48px', md: '58px', xl: '88px' }}
        pb={{ base: '68px', md: '80px', xl: '114px' }}
        id="why-choose-compass"
      >
        <Center w="full" flexDirection="column">
          <Text
            fontSize={{ base: '28px', md: '38px', xl: '48px' }}
            lineHeight={{ base: '38px', md: '48px', xl: '58px' }}
            textAlign="left"
            fontWeight="600"
            color={textColor}
          >
            {t('home.why-choose-compass.heading')}
          </Text>
          <Stack
            spacing={{ base: '40px', md: '60px', xl: '70px' }}
            direction={{ base: 'column', md: 'row' }}
            align="start"
            mt={{ base: '26px', md: '36px', xl: '46px' }}
          >
            {whyCompassText.map((card) => (
              <HomepageCard
                key={card.id}
                icon={card.icon}
                titleText={card.title}
                description={card.description}
              />
            ))}
          </Stack>
          <Link
            href={SUPPORT_URL}
            fontSize="lg"
            textDecoration={'underline'}
            mt={{ base: '25px', md: '35px', xl: '45px' }}
          >
            {t('home.faq')}
          </Link>
        </Center>
      </ContainerBase>
      {data?.allAvailableProducts?.edges?.length > 0 && (
        <ContainerBase bgColor={bgColorGray220} maxW="full" p={0}>
          <Center
            pt={{ base: '25px', md: '30px', xl: '40px' }}
            pb={{ base: '48px', md: '68px', xl: '78px' }}
            flexDirection="column"
          >
            <Text
              color="brand.600"
              fontSize={{ base: '18px', md: '20px', xl: '24px' }}
              lineHeight={{ base: '24px', md: '25px', xl: '29px' }}
              fontWeight={'600'}
            >
              {t('home.meet-miner')}
            </Text>
            <Text
              mt={{ base: '5px', md: '10px' }}
              fontSize={{ base: '24px', md: '26px', xl: '36px' }}
              lineHeight={{ base: '30px', md: '34px', xl: '44px' }}
              fontWeight={'600'}
            >
              {t('home.featured-hardware')}
            </Text>
            <Container
              mt={{ base: '20px', md: '40px', xl: '50px' }}
              maxW={{
                base: '100%',
                lg: '885px',
                xl: '1200px',
              }}
              p={0}
            >
              <Carousel
                gap={30}
                containerHeight={{ base: 'auto', xl: '620px' }}
                containerWidth={1}
                showBottomSlider={data?.allAvailableProducts?.edges?.length > 1}
                alwaysFullWidthContainer
                showNavitationArrows={
                  data?.allAvailableProducts?.edges?.length > 1
                }
              >
                {data?.allAvailableProducts?.edges.map(({ node }: IMiner) => (
                  <ProductCard node={node} key={node.productId} />
                ))}
              </Carousel>
            </Container>
          </Center>
        </ContainerBase>
      )}
      <Center>
        <ContainerBase
          maxWidth={{ '2xl': '1600px' }}
          px={0}
          mx={0}
          pb={{ base: '30px', md: '40px', xl: '60px' }}
        >
          <Center
            flexDirection="column"
            pt={{ base: '32px', md: '22px', xl: '32px' }}
            pb={{ base: '27px', md: '20px', xl: '27px' }}
          >
            <Text
              fontSize={{ base: 'xl', md: '2xl' }}
              fontWeight={'semibold'}
              color="brandBlue.50"
            >
              {t('home.happy-customers')}
            </Text>
            <Text fontSize={{ base: '2xl', md: '4xl' }} fontWeight={'semibold'}>
              {t('home.world-class-service')}
            </Text>
          </Center>
          <Box width="100%" px={{ base: '', md: '', xl: '53px' }}>
            {reviewItemWidth && (
              <Carousel
                gap={32}
                containerHeight={{ base: '240px', md: '335px' }}
                containerWidth={reviewItemWidth}
                alwaysFullWidthContainer
                showNavitationArrows
              >
                {customerReviews.map((review, index) => (
                  <Flex
                    key={index}
                    border="1px solid #EDEEF0"
                    flexDirection="column"
                    overflow="hidden"
                    color="gray.300"
                    bg="base.d100"
                    rounded={5}
                    flex={1}
                    p={5}
                    bgColor={'white'}
                  >
                    <VStack>
                      <Text
                        fontSize={{ base: '18px', md: '24px' }}
                        lineHeight={{ base: '24px', md: '29px' }}
                        textAlign="left"
                        w="full"
                        mb={2}
                        color="black"
                        fontWeight={'semibold'}
                      >
                        {review.fullName}
                      </Text>
                      <Text
                        w="full"
                        color="black"
                        fontSize={{ base: '14px', md: '18px' }}
                        lineHeight={{ base: '20px', md: '22px' }}
                      >
                        {review.review}
                      </Text>
                    </VStack>
                    <HStack
                      spacing={2}
                      w="full"
                      justify={'flex-end'}
                      mt={{ base: '15px', md: '20px' }}
                    >
                      {[...new Array(review.totalStars)].map((arr, index) => {
                        return index < review.activeStars ? (
                          <BsStarFill color="#3F89F6" key={`stars_${index}`} />
                        ) : (
                          <BsStar color="#3F89F6" key={`stars_${index}`} />
                        )
                      })}
                    </HStack>
                  </Flex>
                ))}
              </Carousel>
            )}
          </Box>
        </ContainerBase>
      </Center>
      <ContainerBase
        bgColor={'black'}
        maxW="full"
        pt={{ base: '34px', md: '34px', xl: '44px' }}
        pb={{ base: '60px', md: '80px', xl: '118px' }}
      >
        <Center flexDirection={'column'}>
          <Text
            fontSize={{ base: '20px', md: '18px', xl: '24px' }}
            lineHeight={{ base: '25px', md: '25px', xl: '29px' }}
            fontWeight={'semibold'}
            color="brandYellow.50"
          >
            {homePageData?.pressSubtitle || t('home.news')}
          </Text>
          <Text
            mt={{ base: '8px', md: '8px', xl: '13px' }}
            fontSize={{ base: '24px', md: '26px', xl: '36px' }}
            lineHeight={{ base: '30px', md: '34px', xl: '44px' }}
            fontWeight={'semibold'}
            color="white"
            textAlign={{ base: 'center', md: 'left' }}
          >
            {homePageData?.pressTitle || t('home.press')}
          </Text>
          <Flex
            flexWrap="wrap"
            mt={{ base: '25px', md: '30px', xl: '50px' }}
            maxWidth={{ base: '100%', xl: '1278px' }}
            gridGap={{ base: '20px', md: '33px' }}
            justifyContent="center"
          >
            {homePageData?.press?.map((newsItem: IPress, i: number) => (
              <Link
                key={`press-${i}`}
                href={newsItem?.url}
                target="_blank"
                isExternal
              >
                <Box
                  width={{ base: '100%', md: '294px' }}
                  height={{ base: 'auto', md: '135px' }}
                >
                  <Image
                    src={newsItem?.image?.data?.attributes?.url}
                    alt={
                      newsItem?.image?.data?.attributes?.alternativeText ||
                      `${t('home.press')} ${i + 1}`
                    }
                    objectFit={'cover'}
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Link>
            ))}
          </Flex>
        </Center>
      </ContainerBase>
      <ContainerBase
        maxWidth={{ '2xl': '1600px' }}
        pt={{ base: '40px', md: '70px', xl: '89px' }}
        pb={{ base: '40px', md: '80px', xl: '172px' }}
      >
        <Center flexDirection={'column'} justifyItems="center">
          {homePageData?.statisticsTitle && (
            <Text
              fontSize={{ base: '16px', md: '24px' }}
              lineHeight={{ base: '25px', md: '29px' }}
              fontWeight={'semibold'}
              color={'brand.600'}
            >
              {homePageData?.statisticsTitle}
            </Text>
          )}
          {homePageData?.statisticsSubtitle && (
            <Text
              mt={{ base: '5px', md: '5px', xl: '10px' }}
              fontSize={{ base: '20px', md: '26px', xl: '36px' }}
              lineHeight={{ base: '25px', md: '30px', xl: '44px' }}
              fontWeight={'semibold'}
              color={textColor}
              textAlign={{ base: 'center', sm: 'left' }}
            >
              {homePageData?.statisticsSubtitle}
            </Text>
          )}
        </Center>
        <Flex
          w="full"
          justify={'space-between'}
          flexDirection={{ base: 'column', sm: 'row' }}
          pt={{ base: '40px', md: '50px', xl: '105px' }}
          gridGap={{ base: '30px', md: 'initial' }}
        >
          {homePageData?.statistics?.map((stat, i: number) => (
            <Flex
              key={`stat-${i}`}
              flexDirection={'column'}
              align={'center'}
              justify={'flex-start'}
              w="full"
            >
              {stat.iconImage?.data?.attributes?.url && (
                <Box boxSize={{ base: '64px', md: '36px', xl: '46px' }}>
                  <Image
                    height="100%"
                    width="100%"
                    objectFit="contain"
                    src={stat.iconImage?.data?.attributes?.url}
                    alt={
                      stat.iconImage?.data?.attributes?.alternativeText ||
                      `${stat?.title}`
                    }
                  />
                </Box>
              )}
              {stat?.description && (
                <Text
                  fontSize={{ base: '20px', md: '30px', xl: '44px' }}
                  lineHeight={{ base: '150%', md: '40px', xl: '53px' }}
                  fontWeight={'semibold'}
                  color={textColor}
                  pt={{ base: '15px', md: '20px' }}
                  align={'start'}
                  justifyItems={'flex-end'}
                >
                  {stat?.description}
                </Text>
              )}
              {stat?.title && (
                <Text
                  fontSize={{ base: '12px', md: '12px', xl: '15px' }}
                  lineHeight={{ base: '150%', md: '17px', xl: '19px' }}
                  fontWeight={'semibold'}
                  color={textGray850}
                  w="full"
                  pt={{ base: '8px', md: '10px', xl: '13px' }}
                  align={'center'}
                >
                  {stat?.title}
                </Text>
              )}
            </Flex>
          ))}
        </Flex>
      </ContainerBase>
      <TalktoSales talkToSales={homePageData?.talkToSales || []} />
      <MostRecentCompassContent />
      <Box>
        <ContainerBase
          maxW={{ '2xl': '1600px' }}
          py={14}
          px={{ base: '16px', md: '80px', xl: '210px' }}
        >
          <SubscribeCard
            title={t('home.newsletter.title')}
            subtitle1={t('home.newsletter.subtitle1')}
            subtitle2={t('home.newsletter.subtitle2')}
            buttonTitle={t('buttons.subscribe')}
            email="sales@compassmining.io"
            emailIcon={<HiOutlineMail size={22} />}
            showBubbles={true}
            containerProps={{
              overflow: 'hidden',
              borderRadius: { base: '10px', md: '20px' },
              bgColor: bgColorGray50,
              p: { base: 5, md: 'initial' },
            }}
          />
        </ContainerBase>
      </Box>
    </>
  )
}

export async function getServerSideProps({
  locale,
}: GetServerSidePropsContext) {
  const apolloClient = initializeApollo()
  const { data } = await apolloClient.query({
    query: getHomePageData,
    variables: {
      locale,
    },
    context: {
      clientName: 'strapi',
    },
  })

  if (!data) {
    return addApolloState(apolloClient, {
      notFound: true,
    })
  }

  const homePageData = data.homePage?.data?.attributes || {}

  return {
    props: {
      messages: (await import(`../../locales/${locale}.json`)).default,
      isHomePage: true,
      homePageData,
    },
  }
}

export default HomePage
