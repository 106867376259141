import { ReactNode } from 'react'
import { useTranslations } from 'next-intl'
import { useSubscribe, SubscribeFormValues } from '@/hooks/useSubscribe'
import {
  Text,
  Center,
  VStack,
  Box,
  BoxProps,
  Button,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  FormControl,
  useColorModeValue,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

interface IProps {
  title: string | ReactNode
  subtitle1: string | ReactNode
  subtitle2: string | ReactNode
  emailIcon: ReactNode
  email: string
  // newsletterEmail: string
  buttonTitle?: string
  containerProps?: BoxProps
  showBubbles?: boolean
}

export function SubscribeCard({
  title,
  subtitle1,
  subtitle2,
  buttonTitle,
  emailIcon,
  email,
  containerProps = {},
  showBubbles,
}: IProps) {
  const grayColor = useColorModeValue('#EDEEF0', 'gray.700')

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<SubscribeFormValues>({ defaultValues: { newsletterEmail: '' } })
  const { handleSubscribe, loading } = useSubscribe(reset)

  const t = useTranslations()

  return (
    // <form onSubmit={handleSubmit(onSubmit)}>
    <VStack
      spacing={10}
      w="full"
      justify={'center'}
      bgColor={useColorModeValue('#EDEEF0', 'gray.800')}
      position={showBubbles ? 'relative' : undefined}
      {...containerProps}
    >
      {showBubbles && (
        <>
          <Image
            position="absolute"
            top="0"
            right="0"
            src="/img/bubbles.png"
            display={{ base: 'none', md: 'block' }}
          />
          <Image
            position="absolute"
            bottom="0"
            left="0"
            src="/img/bubbles_responsive.png"
          />
        </>
      )}
      <Box
        w={{ base: 'full', md: 11 / 12, xl: 8 / 12 }}
        maxW="1200px"
        py={20}
        backgroundColor={'gray.50'}
        rounded={'3xl'}
        bgColor={containerProps?.bgColor ? containerProps?.bgColor : grayColor}
      >
        <Center flexDirection={'column'} justifyItems="center">
          <Text
            fontSize={{ base: '4xl', sm: '2xl', lg: '4xl' }}
            color={useColorModeValue('black', 'white')}
            fontWeight={'semibold'}
            mb={3}
            textAlign="center"
          >
            {title}
          </Text>
          <Text
            fontSize={{ base: 'md', sm: 'xl', lg: '2xl' }}
            color={useColorModeValue('black', 'white')}
            fontWeight={'semibold'}
            textAlign="center"
          >
            {subtitle1}
          </Text>
          <Text
            fontSize={{ base: 'md', sm: 'xl', lg: '2xl' }}
            color={useColorModeValue('black', 'white')}
            textAlign="center"
            mt={{ base: 4, sm: 0 }}
          >
            {subtitle2}
          </Text>
          <HStack mt={10} w={{ base: 'full', md: 7 / 12, xl: 7 / 12 }}>
            <FormControl
              id="newsletterEmail"
              isInvalid={!!errors?.newsletterEmail}
            >
              <InputGroup size="lg" borderColor="white" rounded={'xl'} mb={5}>
                <Input
                  id="newsletterEmail"
                  bg="white"
                  placeholder={t('auth.form.enter-email')}
                  fontSize="16px"
                  type="text"
                  borderRadius="2xl"
                  color="black"
                  _placeholder={{ color: 'gray.550' }}
                  {...register('newsletterEmail', {
                    required: t('forms.errors.is-required'),
                  })}
                />
                <InputRightElement width="104px" mr={1}>
                  <Button
                    size={{ base: 'sm', sm: 'md', lg: 'md' }}
                    variant="brand"
                    onClick={handleSubmit(handleSubscribe)}
                    isLoading={loading}
                    loadingText={t('buttons.subscribe')}
                  >
                    {buttonTitle}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </HStack>

          <Stack
            spacing={{ sm: '1', lg: '40' }}
            justify={'center'}
            w="80%"
            pt={5}
            direction={{ base: 'column', sm: 'row' }}
            align={{ base: 'center', sm: 'center' }}
          >
            <HStack mr={2} color="brand.500">
              {emailIcon}
              <Text fontSize="16px" color={useColorModeValue('black', 'white')}>
                {email}
              </Text>
            </HStack>
          </Stack>
        </Center>
      </Box>
    </VStack>
    // </form>
  )
}
