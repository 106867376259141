import { Icon, IconProps } from '@chakra-ui/react'

export function HardwareRackIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 61 61" {...props}>
      <rect
        x="0.89624"
        y="0.824097"
        width="60"
        height="60"
        rx="8"
        fill="currentColor"
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip0_150_8019)">
        <path
          d="M44.7085 36.0743H17.0839C16.4289 36.0743 15.8962 36.607 15.8962 37.2614V43.6364C15.8962 44.2915 16.4289 44.8241 17.0839 44.8241H44.7085C45.3636 44.8241 45.8962 44.2915 45.8962 43.6364V37.2614C45.8962 36.607 45.3636 36.0743 44.7085 36.0743ZM20.2714 42.4493C19.1687 42.4493 18.271 41.5517 18.271 40.4489C18.271 39.3462 19.1687 38.4491 20.2714 38.4491C21.3742 38.4491 22.2712 39.3462 22.2712 40.4489C22.2712 41.5517 21.3742 42.4493 20.2714 42.4493ZM34.0837 41.3864H25.5835C25.0662 41.3864 24.646 40.9669 24.646 40.4489C24.646 39.9316 25.0662 39.5114 25.5835 39.5114H34.0837C34.6017 39.5114 35.0212 39.9316 35.0212 40.4489C35.0212 40.9669 34.6017 41.3864 34.0837 41.3864ZM39.271 41.5118C39.271 42.0292 38.8515 42.4493 38.3335 42.4493C37.8162 42.4493 37.396 42.0292 37.396 41.5118V39.3866C37.396 38.8687 37.8162 38.4491 38.3335 38.4491C38.8515 38.4491 39.271 38.8687 39.271 39.3866V41.5118ZM43.5214 41.5118C43.5214 42.0292 43.1013 42.4493 42.5839 42.4493C42.066 42.4493 41.6464 42.0292 41.6464 41.5118V39.3866C41.6464 38.8687 42.066 38.4491 42.5839 38.4491C43.1013 38.4491 43.5214 38.8687 43.5214 39.3866V41.5118Z"
          fill="currentColor"
        />
        <path
          d="M44.7085 25.4489H17.0839C16.4289 25.4489 15.8962 25.9815 15.8962 26.6365V33.0115C15.8962 33.6666 16.4289 34.1992 17.0839 34.1992H44.7085C45.3636 34.1992 45.8962 33.6666 45.8962 33.0115V26.6365C45.8962 25.9815 45.3636 25.4489 44.7085 25.4489ZM20.2714 31.8238C19.1687 31.8238 18.271 30.9268 18.271 29.824C18.271 28.7213 19.1687 27.8242 20.2714 27.8242C21.3742 27.8242 22.2712 28.7213 22.2712 29.824C22.2712 30.9268 21.3742 31.8238 20.2714 31.8238ZM34.0837 30.7615H25.5835C25.0662 30.7615 24.646 30.342 24.646 29.824C24.646 29.3061 25.0662 28.8865 25.5835 28.8865H34.0837C34.6017 28.8865 35.0212 29.3061 35.0212 29.824C35.0212 30.342 34.6017 30.7615 34.0837 30.7615ZM39.271 30.8863C39.271 31.4043 38.8515 31.8238 38.3335 31.8238C37.8162 31.8238 37.396 31.4043 37.396 30.8863V28.7617C37.396 28.2438 37.8162 27.8242 38.3335 27.8242C38.8515 27.8242 39.271 28.2438 39.271 28.7617V30.8863ZM43.5214 30.8863C43.5214 31.4043 43.1013 31.8238 42.5839 31.8238C42.066 31.8238 41.6464 31.4043 41.6464 30.8863V28.7617C41.6464 28.2438 42.066 27.8242 42.5839 27.8242C43.1013 27.8242 43.5214 28.2438 43.5214 28.7617V30.8863Z"
          fill="currentColor"
        />
        <path
          d="M44.7085 14.8241H17.0839C16.4289 14.8241 15.8962 15.3567 15.8962 16.0118V22.3868C15.8962 23.0413 16.4289 23.5739 17.0839 23.5739H44.7085C45.3636 23.5739 45.8962 23.0413 45.8962 22.3868V16.0118C45.8962 15.3567 45.3636 14.8241 44.7085 14.8241V14.8241ZM20.2714 21.1991C19.1687 21.1991 18.271 20.302 18.271 19.1993C18.271 18.0966 19.1687 17.1989 20.2714 17.1989C21.3742 17.1989 22.2712 18.0966 22.2712 19.1993C22.2712 20.302 21.3742 21.1991 20.2714 21.1991ZM34.0837 20.1368H25.5835C25.0662 20.1368 24.646 19.7167 24.646 19.1993C24.646 18.6813 25.0662 18.2618 25.5835 18.2618H34.0837C34.6017 18.2618 35.0212 18.6813 35.0212 19.1993C35.0212 19.7167 34.6017 20.1368 34.0837 20.1368ZM39.271 20.2616C39.271 20.7796 38.8515 21.1991 38.3335 21.1991C37.8162 21.1991 37.396 20.7796 37.396 20.2616V18.1364C37.396 17.619 37.8162 17.1989 38.3335 17.1989C38.8515 17.1989 39.271 17.619 39.271 18.1364V20.2616ZM43.5214 20.2616C43.5214 20.7796 43.1013 21.1991 42.5839 21.1991C42.066 21.1991 41.6464 20.7796 41.6464 20.2616V18.1364C41.6464 17.619 42.066 17.1989 42.5839 17.1989C43.1013 17.1989 43.5214 17.619 43.5214 18.1364V20.2616Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_150_8019">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(15.8962 14.8241)"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}
