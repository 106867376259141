import { Icon, IconProps } from '@chakra-ui/react'

export function BadgeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 61 61" {...props}>
      <rect
        x="0.89624"
        y="0.824097"
        width="60"
        height="60"
        rx="8"
        fill="#FF6633"
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip0_150_8024)">
        <path
          d="M30.8962 32.5212C33.8091 32.5212 36.1705 30.1598 36.1705 27.2469C36.1705 24.334 33.8091 21.9727 30.8962 21.9727C27.9833 21.9727 25.6219 24.334 25.6219 27.2469C25.6219 30.1598 27.9833 32.5212 30.8962 32.5212Z"
          fill="#FF6633"
        />
        <path
          d="M41.073 25.1172C40.715 24.8511 40.5991 24.4073 40.7751 23.9918C41.279 22.8243 41.0695 22.0165 40.7914 21.5195C40.3819 20.8379 39.654 20.4421 38.6513 20.3442C38.1903 20.2876 37.8555 19.9528 37.8014 19.5133C37.701 18.4891 37.3052 17.7612 36.5988 17.3371C36.1275 17.0727 35.3162 16.8632 34.1582 17.3654C33.7358 17.5414 33.2911 17.428 33.0207 17.0632C31.7674 15.4133 30.0299 15.4065 28.7663 17.0701C28.501 17.428 28.0572 17.544 27.6409 17.368C26.4751 16.8649 25.6656 17.0727 25.1685 17.3517C24.4869 17.7611 24.0912 18.4891 23.9933 19.4918C23.9366 19.9528 23.6018 20.2876 23.1623 20.3417C22.1382 20.4421 21.4103 20.8379 20.9861 21.5443C20.7226 22.0165 20.5132 22.8243 21.0144 23.9857C21.193 24.4072 21.0771 24.8511 20.7123 25.1223C19.8873 25.749 19.4684 26.4632 19.4684 27.247C19.4684 28.0307 19.8873 28.745 20.7191 29.3768C21.0771 29.6429 21.193 30.0867 21.017 30.5022C20.5131 31.6697 20.7226 32.4775 21.0007 32.9745C21.4102 33.6562 22.1382 34.0519 23.1409 34.1498C23.6018 34.2064 23.9366 34.5412 23.9907 34.9808C24.0912 36.0049 24.4869 36.7328 25.1934 37.1569C25.6647 37.4213 26.4733 37.6299 27.6348 37.1286C28.0614 36.9501 28.5009 37.0677 28.7714 37.4308C29.398 38.2557 30.1123 38.6747 30.896 38.6747C31.6798 38.6747 32.394 38.2558 33.0258 37.4239C33.292 37.066 33.7341 36.9526 34.1513 37.126C35.3187 37.63 36.1274 37.4205 36.6236 37.1423C37.3052 36.7329 37.701 36.0049 37.7988 35.0022C37.8555 34.5412 38.1903 34.2064 38.6298 34.1524C39.654 34.0519 40.3819 33.6562 40.806 32.9497C41.0695 32.4775 41.279 31.6698 40.7777 30.5083C40.5991 30.0868 40.715 29.643 41.0798 29.3717C41.9048 28.7451 42.3237 28.0308 42.3237 27.247C42.3237 26.4633 41.9048 25.749 41.073 25.1172V25.1172ZM30.8961 34.2793C27.0186 34.2793 23.8638 31.1246 23.8638 27.247C23.8638 23.3694 27.0186 20.2146 30.8961 20.2146C34.7737 20.2146 37.9285 23.3694 37.9285 27.247C37.9285 31.1246 34.7737 34.2793 30.8961 34.2793V34.2793Z"
          fill="#FF6633"
        />
        <path
          d="M27.7586 39.0742C27.2675 39.2296 26.7757 39.3086 26.2958 39.3086C25.597 39.3095 24.918 39.1352 24.3334 38.8072C24.0363 38.6293 23.7276 38.3704 23.4336 38.0583L21.3907 40.9181C21.1992 41.1859 21.1735 41.5379 21.3237 41.8315C21.4747 42.1242 21.776 42.3079 22.1057 42.3079H25.0785L26.5937 45.3383C26.743 45.6361 27.0469 45.8241 27.38 45.8241C27.7131 45.8241 28.017 45.6361 28.1663 45.3383L30.5748 40.5213C29.7678 40.4576 28.7411 40.1393 27.7586 39.0742V39.0742Z"
          fill="#FF6633"
        />
        <path
          d="M38.3654 38.0674C38.0881 38.3597 37.8005 38.6039 37.5295 38.7669C36.8754 39.1344 36.2049 39.3069 35.4907 39.3069C35.0159 39.3069 34.5275 39.2288 34.0322 39.0734C33.0441 40.1395 32.0212 40.4577 31.2177 40.5214L33.6261 45.3383C33.7755 45.6362 34.0794 45.8242 34.4125 45.8242C34.7455 45.8242 35.0494 45.6362 35.1988 45.3383L36.7139 42.308H39.6867C40.0163 42.308 40.3177 42.1243 40.4688 41.8316C40.619 41.538 40.5932 41.186 40.4018 40.9182L38.3654 38.0674Z"
          fill="#FF6633"
        />
      </g>
      <defs>
        <clipPath id="clip0_150_8024">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(15.8962 15.8241)"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}
