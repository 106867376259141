import { ReactNode } from 'react'
import { Box, Text, Center, VStack, StackProps } from '@chakra-ui/react'

interface IProps extends StackProps {
  titleText: string | ReactNode
  description: string | ReactNode
  icon?: ReactNode
}

export function HomepageCard({
  titleText,
  description,
  icon,
  ...rest
}: IProps) {
  return (
    <VStack rounded="xl" align="start" {...rest}>
      <Box>
        <Center
          justifyContent={{ base: 'left', md: 'center' }}
          mb={{ base: '15px', md: '20px', lg: '24px' }}
        >
          {icon}
        </Center>
        <Text
          fontWeight={'bold'}
          mb={{ base: '12px', md: '15px', lg: '20px' }}
          textAlign={{ base: 'left', sm: 'center' }}
          fontSize={{ base: '18px', md: '18px', lg: '24px' }}
          lineHeight={{ base: '26px', md: '26px', lg: '36px' }}
        >
          {titleText}
        </Text>
        <Text
          fontWeight="500"
          fontSize={{ base: '16px', md: '13px', lg: '16px' }}
          lineHeight={{ base: '22px', md: '18px', lg: '22px' }}
          textAlign={{ base: 'left', sm: 'center' }}
          maxWidth={{ base: '100%', md: '300px' }}
        >
          {description}
        </Text>
      </Box>
    </VStack>
  )
}
