import { Icon, IconProps } from '@chakra-ui/react'

export function GuaranteeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 61 61" fill="none" {...props}>
      <rect
        x="0.89624"
        y="0.824097"
        width={60}
        height={60}
        rx={8}
        fill="currentColor"
        fillOpacity="0.1"
      />
      <path
        d="M43.6703 24.6729V19.3103L30.7844 14.8241L17.8986 19.3103V24.6471C17.8934 24.8172 17.4809 41.5034 30.5369 44.7621L30.7844 44.8241L31.0319 44.7622C43.861 41.5601 43.6857 25.3897 43.6703 24.6729ZM30.4029 35.4703L24.4214 30.0922L25.8034 28.5556L30.1347 32.4591L36.673 24.0489L38.3025 25.3174L30.4029 35.4703Z"
        fill="currentColor"
      />
    </Icon>
  )
}
