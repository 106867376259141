export const customerReviews = [
  {
    fullName: 'Fabio C.',
    review:
      "Great service. I've been mining with them for 10 months and everything works perfectly. Communication always on time, support in more than one channel. Good prices and fair servicing rate.",
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Sergio R.',
    review:
      'INCREDIBLE customer service! the miner arrived on time (exactly on time) and next step is to configure the robocop so they already sent me the instructions and lets see how it goes!',
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Craig L.',
    review:
      'Compass has done a great job of keeping responding to my customer service requests and recently gave me the ability to move to a different hosting facility to get online faster.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Rogerio D.',
    review:
      "I never could mine at home because asic's noise and my local energy price. Thanks Compass for your service!! Delays are normal in miner industry and rack space are so scarce...",
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Ian C.',
    review:
      'Compass is great. They sourced fair priced ASICs. Up time has been great and when one of my miners went down, they responded quickly and it was hashing in less than 10 hours',
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Jesse O.',
    review:
      "Excellent experience with Compass. Extremely responsive staff, and best hosting prices around bar none. And actually the best prices I've been able to find on ASICs as well.",
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Devin K.',
    review:
      "Some of the best customer service I've experienced in the crypto space. I have miners purchased and hosted from a variety of places and compass really makes it easy.",
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Roseberry F.',
    review:
      "First time miner here... Compass Mining provides the easiest way to break into mining, with product, placement and guidance. I'm very happy I chose Compass Mining.",
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Pasha E.',
    review:
      'Compass is the only company I work with in this crypto world of mining. They are incredibly focused on customer service. Like I said I only trust Compass Mining.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Tom W.',
    review:
      "Compass is as professional as they come for bitcoin mining. I've referred family and friends to compass. I couldn't give a strongerreviewof compass mining.",
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Brendten E.',
    review:
      'Compass Mining customer support is fantastic. Super friendly, very responsive, and able to resolve issues quickly. Great experience with this company so far.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Oliver W.',
    review:
      'The Compass team has been extremely responsive to issues, and there is no way I could mine without Compass because my housing situation would not permit it.',
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Pat A.',
    review:
      'I’ve been a customer since Jan of 2021 and could not be happier with the service and support. Fantastic, growing organization with very knowledgeable team.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Nik S.',
    review:
      'Compass has been amazing helping the common man/woman to begin mining.. Their support staff are super nice and helpful. No complaints.. Amazing service :)',
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Joseph M.',
    review:
      'I had a quick question for Compass and they responded within 4 hours of my submission. They were very informative and provided the answer I needed.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Enrique M.',
    review:
      'The best experience mining is through Compass mining and the great team they have.\n\nLooking forward to continuing doing business with you guys !!!',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'AJ M.',
    review:
      'Compass Mining responded quickly. Support got back to me within a day, had checked my equipment, and assured me everything would work as expected.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Joaquim H.',
    review:
      'Very nice and reliable company, this is not an easy industry, you can have sure they work hard to keep your machines up and running.',
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Aaron H.',
    review:
      'Consistently great service and support from the Compass folks and their effort in trying to be transparent as much as possible!',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Eduardo B.',
    review:
      'They offer a reliable service, including selling miners and hosting them. In addition, their customer service is really good.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Alex S.',
    review:
      'Compass Mining was very easy to set up my new miners and their concierge staff was quick to respond and answer any questions!',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Tyler B.',
    review:
      'These guys are awesome. Very responsive, always helpful. Love compass. Wish I could get 4 million more miners up and running.',
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Carlos R.',
    review:
      'Smooth purchasing process, everyone is ready to help out and support any question/issue that might arise. Very happy so far!',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Teddy A.',
    review:
      'Quality product & great service. Very attentive & quick to respond to any questions. They clearly care about their business.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Hani A.',
    review:
      'Compass has the best customer service in this industry!!\n\nthank you for the swift email responses and quick activation !',
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Leonard P.',
    review:
      'Prompt response and resolution. The service uplift my mood after my horrendous experience with other service provider.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Callie W.',
    review:
      'In the beginning of the process but the communication is great and their team is super clear, timely, and responsive.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Yowan J.',
    review:
      'Joined after reading some goodreview and so far has been great. Miner came online earlier than expected.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Andrew B.',
    review:
      'Love mining with Compass, they make the process simple and easy to navigate. Happy Hashing my friends!',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'William T.',
    review:
      'Compass really does make becoming a part of future of finance an easy and inexpensive experience.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Todd R.',
    review:
      'Compass makes mining simple as hailing an Uber, with top notch customer service. Thanks.',
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Bill B.',
    review:
      "Compass Mining helped me get started mining Bitcoin and I couldn't be happier so far.",
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Herb B.',
    review:
      'Responsive, knowledgeable, and committed to their customers success in mining.',
    activeStars: 5,
    totalStars: 5,
  },
  {
    fullName: 'Santiago R.',
    review:
      'The Compass team has been on point and has made the process seamless. Cheers!',
    activeStars: 4,
    totalStars: 5,
  },
  {
    fullName: 'Audio I.',
    review:
      'Top class service. Been using since September 1st and Very impressed so far',
    activeStars: 5,
    totalStars: 5,
  },
]
