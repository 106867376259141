import { useTranslations } from 'next-intl'
import NextLink from 'next/link'
import {
  Box,
  chakra,
  Stack,
  Flex,
  Text,
  useColorModeValue,
  Button,
} from '@chakra-ui/react'
import { FiArrowDown } from 'react-icons/fi'
import { ContainerBase } from './containers/ContainerBase'

export function Hero() {
  const t = useTranslations()
  return (
    <Box
      bg={'black'}
      bgImage={{
        base: 'url(/img/miner-hero-with-bg.png)',
        md: 'url(/img/miner-hero-with-bg.png)',
      }}
      backgroundSize="contain"
      backgroundPosition={'bottom'}
      backgroundRepeat="no-repeat"
      minW={{ base: '100%', lg: '80%' }}
      minHeight={{ base: '500px', md: '439px', lg: '539px', xl: '839px' }}
      pt={{ base: '80px', md: '100px', xl: '255px' }}
      pb={{ base: '80px', md: '200px', xl: '307px' }}
    >
      <ContainerBase
        maxWidth={{ xl: '1600px' }}
        py={0}
        px={{ base: '32px', md: '80px', xl: '200px' }}
      >
        <Stack
          zIndex={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={6}
          height="100%"
        >
          <chakra.h1
            fontSize={{
              base: '45px',
              md: '75px',
              xl: '100px',
            }}
            lineHeight={'100%'}
            justifySelf="center"
            textAlign="center"
            color={useColorModeValue('white', 'white')}
            textShadow="0px 4px 6px rgba(0, 0, 0, 0.33)"
          >
            {t.rich('slogan.title', {
              bold: (children) => (
                <Text as="span" fontWeight="600">
                  {children}
                </Text>
              ),
            })}
            <br />
          </chakra.h1>
          <Flex
            gridGap={{ base: 2, md: '27px' }}
            flexWrap="wrap"
            justifyContent="center"
          >
            <NextLink href="/hardware" passHref>
              <Button
                as="a"
                variant="brand"
                mb={{ base: 2, sm: 0 }}
                zIndex={5}
                width={{ base: '200px', md: '150px', xl: '200px' }}
                py="6"
              >
                {t('buttons.start-now')}
              </Button>
            </NextLink>
            <NextLink href="#why-choose-compass" passHref scroll={false}>
              <Button
                as="a"
                bg={useColorModeValue('gray.50', 'gray.800')}
                mb={{ base: 2, sm: 0 }}
                zIndex={5}
                width={{ base: '200px', md: '150px', xl: '200px' }}
                rightIcon={<FiArrowDown />}
                py="6"
                color={useColorModeValue('black', 'white')}
              >
                {t('buttons.learn-more')}
              </Button>
            </NextLink>
          </Flex>
        </Stack>
      </ContainerBase>
    </Box>
  )
}
