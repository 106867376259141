import { useEffect } from 'react'
import { useTranslations } from 'next-intl'
import { useBlogCms } from '@/hooks/useBlogCms'
import {
  useColorModeValue,
  Box,
  Center,
  Link,
  Text,
  Flex,
  Image,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { ContainerBase } from '@/components/containers/ContainerBase'
import { BLOG_URL } from '@/constants'

interface PostItem {
  id: string
  url: string
  feature_image: string
  title: string
  excerpt: string
}

export const MostRecentCompassContent = () => {
  const t = useTranslations()
  const bgColorGray50 = useColorModeValue('gray.50', 'gray.800')
  const { compassContent, getContent } = useBlogCms()

  useEffect(() => {
    const abortController = new AbortController()
    getContent(
      {
        path: '/content/posts',
        params: '&limit=3&order=published_at%20desc',
      },
      abortController
    )

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <Box backgroundColor={bgColorGray50}>
      <ContainerBase
        pt={'35px'}
        pb={'30px'}
        px={{ base: '16px', md: '50px', xl: '50px', '2xl': '243px' }}
        maxWidth={{ '2xl': '1600px' }}
      >
        <Center flexDirection="column" alignItems="flex-start">
          <Text
            fontWeight="600"
            mt={{ base: '5px', md: '7px', xl: '11px' }}
            fontSize={{ base: '20px', md: '26px', xl: '36px' }}
            lineHeight={{ base: '150%', md: '34px', xl: '44px' }}
          >
            {t('home.compass-content')}
          </Text>
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
            justifyContent="stretch"
            mt={{ base: '20px', md: '24px', xl: '34px' }}
            gridGap={{ base: '40px', md: '40px', xl: '60px' }}
          >
            {compassContent?.posts?.map((item: PostItem) => {
              return (
                <NextLink href={item.url} key={item?.id} passHref>
                  <Link
                    flex="1"
                    variant="nohover"
                    alignSelf="stretch"
                    display="grid"
                  >
                    <Box
                      bg="white"
                      minWidth={{ '2xl': '333px' }}
                      boxShadow="0px 1px 10px rgba(0, 0, 0, 0.25)"
                    >
                      <Box
                        width="100%"
                        height={{ xl: '180px', '2xl': '200px' }}
                      >
                        <Image
                          width="100%"
                          height="100%"
                          objectFit="cover"
                          objectPosition="top"
                          src={item.feature_image}
                          alt="content image"
                        />
                      </Box>
                      <Box
                        color="black"
                        padding={{
                          base: '15px 10px',
                          md: '15px 10px',
                          xl: '20px 15px',
                        }}
                      >
                        <Text
                          fontWeight="500"
                          fontSize={{ base: '18px', md: '18px', xl: '22px' }}
                          lineHeight={{ base: '150%', md: '23px', xl: '30px' }}
                        >
                          {item.title}
                        </Text>
                        <Text
                          mt={{ base: '15px', md: '15px', xl: '20px' }}
                          fontSize={{ base: '12px', md: '12px', xl: '14px' }}
                          lineHeight={{ base: '150%', md: '16px', xl: '18px' }}
                          css={{
                            display: '-webkit-box',
                            'WebkitLineClamp': '4',
                            'WebkitBoxOrient': 'vertical',
                            overflow: 'hidden',
                          }}
                        >
                          {item.excerpt}
                        </Text>
                      </Box>
                    </Box>
                  </Link>
                </NextLink>
              )
            })}
          </Flex>
          <NextLink href={BLOG_URL} passHref>
            <Link variant="colored" color="brand.600">
              <Text
                fontWeight="500"
                mt={2}
                fontSize={{ base: '14px', md: '14px', xl: '16px' }}
                lineHeight={{ base: '150%', md: '20px', xl: '22px' }}
              >
                {t('home.read-more')}
              </Text>
            </Link>
          </NextLink>
        </Center>
      </ContainerBase>
    </Box>
  )
}
