import { STRAPI_URL } from '@/constants'

export function getStrapiMedia(url: string): string {
  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith('http') || url.startsWith('//')) {
    return url
  }

  // Otherwise prepend the URL path with the Strapi URL
  return `${STRAPI_URL}${url}`
}
