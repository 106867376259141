import { useState } from 'react'
import { BLOG_CONTENT_API } from '@/constants'
import { replaceString } from '@/utils/replaceString'

interface IResult {
  tags?: any
  authors?: any
  posts: any[]
  meta?: any
}
export const useBlogCms = () => {
  const [loading, setLoading] = useState(true)
  const [compassContent, setList] = useState<IResult>({ posts: [] })

  const resource = (path: string) => {
    return replaceString(BLOG_CONTENT_API, {
      key: 'resource',
      value: path,
    })
  }

  const getContent = async (
    {
      path,
      params,
    }: {
      path: string
      params?: string
    },
    abortController?: AbortController
  ) => {
    setLoading(true)
    try {
      const res = await fetch(`${resource(path)}${params}`, {
        signal: abortController?.signal,
      })
      const content = await res.json()
      setList(content)
      setLoading(false)
    } catch (e) {
      setList({ posts: [] })
      setLoading(false)
    }
  }

  return { compassContent, getContent, loading }
}
