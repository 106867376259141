import { useRouter } from 'next/router'
import { useAuthContext } from './useAuthContext'

/**
 * Makes a request (query or mutation) with checking if route is protected or not
 * @param {Function} apolloRequest - request (query or mutation)
 * @param {Boolean} requireAuth
 * @returns {Function} request to execute (query or mutation)
 */
// TODO temporary solution until postgraphile doesn't throw unauthenticated error
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const useAuthRequestProtection = (
  apolloRequest: any,
  requireAuth = true
): any => {
  const { isAuthenticated, isFetched, isEmailVerified } = useAuthContext()
  const { push, asPath: currentPathname } = useRouter()

  return () => {
    // this is public endpoint. Make request
    if (!requireAuth) {
      return apolloRequest()
    }
    // this is private endpoint. Make request only if user is authenticated and email is verified
    if (isAuthenticated) {
      if (!isEmailVerified) {
        push({
          pathname: '/verification-required',
          query: {
            return_to: currentPathname,
          },
        })
      }
      return apolloRequest()
    }
    // otherwise - redirect user
    if (!isAuthenticated && isFetched) {
      push({
        pathname: '/sign-in',
        query: {
          return_to: currentPathname,
        },
      })
    }
  }
}
